import React from "react";
import Header from "../components/Header";
import HeaderTabs from "../components/HeaderTabs";
import { useEffect, useContext, useState } from "react";
import { AppContext } from "../ContextApi";
import CardLoader from "../components/CardLoader.js";
import NoDataFound from "../components/NoDataFound";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NotokenFound } from "../Utils";
import { DataNotFoundIcon } from "../Assets/index";
import ClaimsFeatures from "../components/ClaimsFeatures.js";
import Wellness_Benefits from "./Wellness_Benefits.js";
import HeroSection from "../components/HeroSection.js";
import InsuranceProducts from "../components/InsuranceProducts.js";
import { mainAPikey } from "../ContextApi";

const Policydetails = () => {
  const [policyDetailsdata, setpolicyDetailsdata] = useState([]);
  const [isDataLoading, setDataLoading] = useState(true);
  const [dataMsg, setdataMsg] = useState("");
  const [logos, setLogos] = useState("");
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const { activeElementId, requestOptions, setRequestOptions, tabapiData , featuresType } =
    useContext(AppContext);
  const navigate = useNavigate();

  const [appLinkToken , setappLinkToken] = useState(null)

  const requestOptionsNew = { ...requestOptions };
  requestOptionsNew.method = "POST";

  function handleTokenInResponse(response) {
    if (response.status === 401 || token === "") {
      NotokenFound(navigate);
    } else {
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
        setToken(authorizationToken);

        return updatedResponse;
      } else {
        return response;
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (activeElementId) {
        setDataLoading(true);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/${mainAPikey}/employee/policy-details/?policy_id=${activeElementId}`,
            requestOptions
          );

          if (response.status === 429) {
            toast.error("Oops! You have reached maximum limit, please try again after sometime.");
          }

          const data = await handleTokenInResponse(response);
          const res = await response.json();

          if (res.success) {
            setpolicyDetailsdata(res.data);
            setLogos(res.logo);
          } else {
            setpolicyDetailsdata(res.data);
            setdataMsg(res.message);
            setLogos(res.logo);
          }

          setDataLoading(false);

          if (!token) {
            NotokenFound(navigate);
          }
        } catch (error) {
          if (error.message === 'Too Many Requests') {
            toast.error('Too many requests. Please try again later.');
          } else {
            console.error("Error fetching data:", error);
          }

          setDataLoading(false);
        }
      }
    };

    fetchData();
  }, [activeElementId]);


  useEffect(() => {
    if (!token) {
      NotokenFound(navigate);
    }
  }, []);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
    if(mainAPikey) {
      async function getV1TokenAutoLogin() {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL + `/${mainAPikey}` +
              `/employee/auto-login`,
              requestOptionsNew
          );
  
          const data = await response.json();
          if(data.status === true){
            localStorage.setItem("appLinkToken" , data.access_token)
            setappLinkToken(data.access_token)
          }
        } catch (error) {
          console.error(error);
        }
      }
      getV1TokenAutoLogin();
    }

  }, [token , mainAPikey]);


  return (
    <>
      <Header />
      <HeroSection />
      {tabapiData.length > 0 && (
        <>
          <div className="claims-tabs-section-cover-wrapper">
            <div className="row">
              <div className="col-lg-4">
                <div className="claims-header-tabs">
                  <HeaderTabs />
                  <div className="separater"></div>
                  <ClaimsFeatures />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="policy-details-main-cover-wrapper">
                  {isDataLoading ? (
                    <CardLoader />
                  ) : (
                    <div className="policy-details-cover">
                      {policyDetailsdata.length === 0 ? (
                        <NoDataFound msg={dataMsg} />
                      ) : (
                        <div className="row d-flex justify-content-center">
                          <div className="col-lg-6">
                            <div className="row">
                              {policyDetailsdata.insurer_name && (
                                <div className="col-lg-12 mb-4">
                                  <div className="policy-details-content-box">
                                    <div className="policy-details-image-box">
                                      <img src={logos.insurer_logo} alt />
                                    </div>
                                    <div className="policy-details-text-box">
                                      <h4>Insurance company name :</h4>
                                      <p>{policyDetailsdata.insurer_name}</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {policyDetailsdata.tpa_name && (featuresType === "GMC" || featuresType == "Top Up Policy" || featuresType == "Parent Policy") && (
                                  <div className="col-lg-12 mb-4">
                                    <div className="policy-details-content-box">
                                      <div className="policy-details-image-box">
                                        <img src={logos.insurer_logo} alt="Insurer Logo" />
                                      </div>
                                      <div className="policy-details-text-box">
                                        <h4>Tpa Name :</h4>
                                        <p>{policyDetailsdata.tpa_name}</p>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {policyDetailsdata.policy_number && (
                                <div className="col-lg-12 mb-4">
                                  <div className="policy-details-content-box">
                                    <div className="policy-details-image-box">
                                      <img
                                        src={logos.policy_number_logo}
                                        alt="policy_number_logo"
                                      />
                                    </div>
                                    <div className="policy-details-text-box">
                                      <h4>Policy number :</h4>
                                      <p>{policyDetailsdata.policy_number}</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {policyDetailsdata.policy_type && (
                                <div className="col-lg-12 mb-4">
                                  <div className="policy-details-content-box">
                                    <div className="policy-details-image-box">
                                      <img
                                        src={logos.policy_type_logo}
                                        alt="policy_type_logo"
                                      />
                                    </div>
                                    <div className="policy-details-text-box">
                                      <h4>Policy type :</h4>
                                      <p>{policyDetailsdata.policy_type}</p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row">
                              {policyDetailsdata.policy_start_date && (
                                <div className="col-lg-12 mb-4">
                                  <div className="policy-details-content-box">
                                    <div className="policy-details-image-box">
                                      <img
                                        src={logos.policy_start_date_logo}
                                        alt="policy_start_date_logo"
                                      />
                                    </div>
                                    <div className="policy-details-text-box">
                                      <h4>Policy Start Date :</h4>
                                      <p>
                                        {(() => {
                                          const date = new Date(
                                            policyDetailsdata.policy_start_date
                                          );
                                          const day = date.getDate();
                                          const month = date.toLocaleString(
                                            "en-us",
                                            {
                                              month: "long",
                                            }
                                          );
                                          const year = date.getFullYear();
                                          const formattedDate = `${day} ${month} ${year}`;
                                          return formattedDate;
                                        })()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {policyDetailsdata.policy_expiry_date && (
                                <div className="col-lg-12 mb-4">
                                  <div className="policy-details-content-box">
                                    <div className="policy-details-image-box">
                                      <img
                                        src={logos.policy_expiry_date_logo}
                                        alt="policy_expiry_date_logo"
                                      />
                                    </div>
                                    <div className="policy-details-text-box">
                                      <h4>Policy Expiry Date :</h4>
                                      <p>
                                        {(() => {
                                          const date = new Date(
                                            policyDetailsdata.policy_expiry_date
                                          );
                                          const day = date.getDate();
                                          const month = date.toLocaleString(
                                            "en-us",
                                            {
                                              month: "long",
                                            }
                                          );
                                          const year = date.getFullYear();
                                          const formattedDate = `${day} ${month} ${year}`;
                                          return formattedDate;
                                        })()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {policyDetailsdata.sum_insured && (
                                <div className="col-lg-12 mb-4">
                                  <div className="policy-details-content-box">
                                    <div className="policy-details-image-box">
                                      <img
                                        src={logos.sum_insured_logo}
                                        alt="sum_insured_logo"
                                      />
                                    </div>
                                    <div className="policy-details-text-box">
                                      <h4>Sum Insured :</h4>
                                      <p>
                                        {(() => {
                                          const formattedNumber =
                                            policyDetailsdata.sum_insured.toLocaleString(
                                              "en-IN",
                                              {
                                                style: "currency",
                                                currency: "INR",
                                              }
                                            );
                                          return formattedNumber;
                                        })()}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* <InsuranceProducts/> */} 
      <Wellness_Benefits appLinkToken={appLinkToken} />
      <ToastContainer
    position="top-center"
/>
    </>
  );
};

const EmptyDataComponent = ({ msg }) => {
  return (
    <>
      <h4>{msg}</h4>
    </>
  );
};

export default Policydetails;